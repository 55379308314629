/* Header.module.css */

.headerComponent {
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(0.25turn, #101010, #2a2a2a, #101010);
  border-image-slice: 1;
  box-shadow: 0 0 20px 22px #00000064;   
  width:100%;
  z-index: 10;
  background-color: #000000;
  background-image: url("https://www.transparenttextures.com/patterns/egg-shell.png");
}



.headerBox {
   display: flex;
   height: 70px;
  
   @media screen and (min-width: 768px) {
    height: 70px;
   };
 
   @media screen and (min-width: 1200px) {
     height: 70px;
   };
 }

.headerList {
  display: flex;
  padding: 0 30px;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between; 
  };
} 

.headerList li {
  display: flex;
  align-items: center;
} 


.headerNav {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  font-size: 11px;
  margin-left: 10px;
  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }; 

  @media screen and (max-width: 767.9px) {
    display: none;
  };

 }

 .headerLinkText {
  color: #707070;
  text-shadow: 1px 1px 1px #2c2c2c;
  transition: all .4s ease-out;
}

 .activeLink h4 {
 color: #600000;
 text-shadow: 1px 1px 1px #270000;
 transition: all .4s ease-out;
}

 .headerNav a:hover h4 {
 color: #600000;
 text-shadow: 1px 1px 1px #4e0000;
 transition: all .4s ease-out;
}

.headerInfo {
   display: grid;
   width: 100%;

   @media screen and (max-width: 767.9px) {
     display: none; 
   };

   @media screen and (min-width: 1200px) {
    margin-right: 20px;
    
  }; 
}

.headerInfo li {
  display: flex;
  width: 100%;
  padding:  0;
  align-items: center;
  justify-content: end;
  text-align: center;
  padding: 1px 0;
  
 @media screen and (min-width: 1200px) {
}; 

}


.headerInfoIconLocation {
padding-right: 1.9px;

}

.headerInfo h3 {
 margin: 0;
 font-size: 11px;
 padding-left: 2px;

 color: #707070;
 text-shadow: 1px 1px 1px #2c2c2c;


 @media screen and (min-width: 1200px) {
  font-size: 12px;
  padding-left: 7px;
}; 
}

.burgerLinesIcon {
  position: absolute;
  right: 35px;
  top: 18px;
  
   @media screen and (min-width: 767.9px) {
     display: none; 
   }

 }

.headerInfoIcon {
 height: 15px;

 @media screen and (min-width: 767.9px) {
  height: 20px;
  margin-right: 2px;
}
}

.headerLogo {
  position: relative;
  display: flex;

  @media screen and (min-width: 768px) {
     padding-right: 100px;
  };

  @media screen and (min-width: 1200px) {
     padding-right: 200px;
  };
}

/* .headerLogo:after {
  position: absolute;
  content: "";
 display: flex;
 height: 4px;
 width: 123px;
 bottom: 0px;
 left: 0.5px;
 background-image: url("images/bottomLine2.svg");
 background-repeat: repeat;
 background-size: cover;

 @media screen and (min-width: 767.9px) {
  left: 6px;
  height: 5px;
 width: 193px;
};

@media screen and (min-width: 1200px) {
  
  content: "";
 display: flex;

 bottom: 0px;
 left: 0.5px;
 background-image: url("images/bottomLine2.svg");

  height: 7px;
 width: 196px;
};
} */

 
 