.addButton {
   display: flex;
   height: 100%;
   width: 100%;
   margin: 0;
   align-items: center;
   justify-content: center;
   font-weight: 800;
   transition: all 0.4s ease-out;
   cursor: pointer;
   color: black;
   font-family: Primary;
   overflow: hidden;
   border-radius: 10px;
   text-shadow: 0px 0px 11px #fc000030;
   -webkit-text-stroke: .7px rgba(138, 137, 137, 0.76);
   font-size: 33px;
     
}


.added {
   background-color: #c9c7c764;
 }
 
 .notAdded {
   fill: green;
   color: black;
 }