.footer {
  position: relative;
  display: flex;
  justify-content: center;

  height: auto;
  width: 100%;

  border-top: 1px solid transparent;
  border-image: linear-gradient(0.25turn, #101010, #2a2a2a, #101010);
  border-image-slice: 1;

  box-shadow: 0 0 26px 22px #00000064;

background-color: #000000;
background-image: url("https://www.transparenttextures.com/patterns/egg-shell.png");



@media screen and (min-width: 768px) {
    height: 170px;
  };

  @media screen and (min-width: 1200px) {
    height: 210px;
  }
}
  

.footerContainer {
  width: 100%;
 
}


  
.footerBox {
 padding: 40px 0 0;
 height: 160px;
}
.footerLogo {
  position: relative;
 margin: 0;
 display: flex;
 justify-content: center;
}




.footerAllRights {
  display: flex;
  justify-content: center;
 

  color: #3c3c3c;
  font-size: 9px;
  margin-top: 30px;
  margin-bottom: 30px;
  
}

.customFooter {
 background-color: #fff;
}

.footerAllRights h5 {
 margin: 0;
}

.footerSocial {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  
  /* background-color: #fff; */
}



.footerSocial li img {
  height: 22px;
}

.footerSocial li {
  margin: 0 15px;
}


.footerList {
  display: grid;
  justify-content: center;
  align-items: center;
}

.footerList li{
  display: grid;
  justify-content: center;
  align-items: center;
  
}

.logoFooter {
 margin-top: 50px;
}
