.fadeOut {
   opacity: 0; 
 }

.menuPage {
   height: 100%;
   background-position: bottom;
   background-repeat: repeat;
 
   background-color: #000000;
 
   background-image: url("https://www.transparenttextures.com/patterns/white-wall-3-2.png");
   background-position: left;

   @media screen and (min-width: 767.9px) {
         
      };

   @media screen and (min-width: 1200px) {
    /* background-color: #020202; */

      background-attachment: fixed;
   };
}


.menuPageContainer {
   width: 100%;
}


.menuTitle {
   display: flex;
   height: 95px;
   justify-content: center;
   align-items: center;
   font-size: 15px;
   font-weight: 900;
   margin: 0;
   color: #d2121200;

   @media screen and (min-width: 768px) {
      height: 95px;
   };
   @media screen and (min-width: 1200px) {
      height: 95px;
   };
}

.dishList {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   grid-column-gap: 30px;
   grid-row-gap: 30px;
   padding: 10px 20px 50px 20px;

   @media screen and (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 30px;
      padding: 20px 70px 70px 70px;
   }; 

   @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 35px;
      grid-row-gap: 35px;
      padding: 30px 90px 90px 90px;

   }; 
}

.dishCard {
   position: relative;
   display: flex;
   width: 100%;
   height: 130px;
   justify-content: space-between;
   align-items: center;
   border-radius: 15px;
   overflow: hidden;
   margin-left: auto;
   margin-right: auto;
   transition: all .4s ease-out;
   box-shadow: rgba(0, 0, 0, 0.441) 3px 3px 10px 4px;
   border: none;
   background-color: #262626;
   background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");

   @media screen and (min-width: 767.9px) {
      height: 180px;
      border-radius: 17px;
   }; 

   @media screen and (min-width: 1200px) {
      height: 200px;
      border-radius: 19px;
   }; 
}

.dishCardLink {
   display: flex;
   width: 100%;
   height: 100%;
   justify-content: space-around;
   align-items: center;
   border-radius: 15px; 
   box-shadow:
    inset #000000b0 -5px -5px 9px,
    inset #7d7d7d93 5px 5px 9px; 

    box-shadow:
inset #000000b0 -3px -3px 9px,
inset #7d7d7d93 3px 3px 9px ; 

    @media screen and (min-width: 768px) {
    
      height: 180px;
      border-radius: 17px;
   }; 

   @media screen and (min-width: 1200px) {
     
      height: 200px;
      border-radius: 19px;
   }; 
}

.dishCard:hover,
.dishCard:active,
.dishCard:focus {
   box-shadow:  2px 2px 15px 2px #0000005d;
   box-shadow: rgba(0, 0, 0, 0.273) 3px 3px 10px 6px;
   transition: all .4s ease-out;
   scale: 1.02;
  
}
.dishCard h3 {
   display: flex;
   font-weight: 900;
   color: #949494;
   text-shadow: 0px 0px 5px black;

   background-color: rgba(0, 0, 0, 0);
   border-radius: 4px;

   transition: all .4s ease-out;

   @media screen and (min-width: 768px) {
    
      color: #949494;
   }; 

   @media screen and (min-width: 1200px) {
      color: #949494;
     
   }; 
}

.dishCard:hover h3,
.dishCard:active h3,
.dishCard:focus h3 {
   color: #e9e9e9;
   transition: all .4s ease-out;
}

.dishCard .dishCardImage {
   transition: all .4s ease-out;
}


.dishCardImg {
   display: flex;
   justify-content: left;
   
   @media screen and (min-width: 767.9px) {
   }; 
   
   @media screen and (min-width: 1200px) {

   }; 
   }
   
   
   .dishCardImage {
      display: block;
      position: absolute;
      height: 115px;
      width: 115px;
      rotate: -3deg;
      scale: 1.2;
      bottom: 0px;
      left: 0px;
      opacity: 1;
      /* z-index: 0; */
   
    @media screen and (max-width: 375px) {
      height: 145px;
      width: 145px;
      

    
   };  
    @media screen and (min-width: 767.9px) {
    
      height: 155px;
      width: 155px;

      bottom: 0px;
      left: 0px;
     
      z-index: 0;
   }; 
   
   @media screen and (min-width: 1200px) {
  
      height: 210px;
      width: 210px;
    
      bottom: 0px;
      left: 0px;
    
   }; 
   }
   

.dishCard:hover .dishCardImage,
.dishCard:active .dishCardImage,
.dishCard:focus .dishCardImage {
   transition: all .4s ease-out;
  

   @media screen and (min-width: 768px) {

   }; 

   @media screen and (min-width: 1200px) {

     
   }; 
}






.dishCardDescriptionBox{
   position: absolute;
   right: 15px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   line-height: 13px;

   @media screen and (min-width: 768px) {
      right: 20px;
   }; 

   @media screen and (min-width: 1200px) {
      right: 25px;
   }; 
}

.dishCardDescriptionTop {
   display: flex;
   justify-content:center;
   align-items: center;
   margin: 0;
   
   z-index: 5;
   font-size: 13px;
  
   @media screen and (min-width: 768px) {
      font-size: 15px;
   }; 
   
   @media screen and (min-width: 1200px) {
      font-size: 17px;
      
   }; 
}

.dishCardDescriptionBottom {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   margin: 0;
   font-size: 17px;
   letter-spacing: .5px;
   z-index: 5;
   font-size: 8px;
   flex-wrap: nowrap;

   @media screen and (min-width: 768px) {
      font-size: 11px;
   }; 
   
   @media screen and (min-width: 1200px) {
      font-size: 14px;
};

}

.menuBasketInfoIcon {
 position: relative;
}

.toBasketButton {
   position: absolute;
   display: flex;
   flex-direction: column;
   top: 92px;
   right: 20px; 
   height: 60px;
   width: 60px;
   border-radius: 13px;
   overflow: hidden;
   border: none;
   box-shadow: rgba(0, 0, 0, 0.608) 3px 3px 10px 4px;
   z-index: 9;
   transition: all .4s ease-out;
   background-color: #262626;
   background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
    
    @media screen and (min-width: 767.9px) {
       width: 150px;
       flex-direction: row-reverse;
       right: 40px; 
      
    };

       @media screen and (min-width: 1200px) {
          
          right: 80px; 
         
       };
}

.toBasketLink {
 position: relative;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: end;
 height: 100%;
 width: 100%;
 box-shadow:
  inset #000000b0 -5px -5px 9px,
  inset #7d7d7d93 5px 5px 9px; 

  box-shadow:
inset #000000b0 -3px -3px 9px,
inset #7d7d7d93 3px 3px 9px ; 
  
    @media screen and (min-width: 767.9px) {
       flex-direction: row;
       justify-content: end;
    };

       @media screen and (min-width: 1200px) {
    
       };
}

.toBasketDescription {
  margin: 0;
  font-size: 9px;
  transition: all .4s ease-out;
  color: #949494;
  text-shadow: 1px 1px 1px black;
  font-weight: 900;
  margin-bottom: 9px;
  display: none;

   @media screen and (min-width: 767.9px) {
      display: flex;
    margin-bottom: 0px;
    margin-right: 9px;
    font-size: 15px;
    
 };

    @media screen and (min-width: 1200px) {
     
    };
}

.toBasketButton:hover .toBasketDescription {

 color: #e9e9e9;
     transition: all .4s ease-out;
}

.toBasketButton:hover {
 scale: 1.02;
 transition: all .4s ease-out;
 box-shadow: rgba(0, 0, 0, 0.273) 3px 3px 10px 6px;
}

.basketIcon {
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
  /* opacity: .6; */
   height: 45px;
       width: 45px;
       top: 7px;
       left: 7px;

       /* transform: scaleX(-1); */

    @media screen and (min-width: 767.9px) {
     
     
       margin-bottom: 0px;
       height: 55px;
       width: 55px;
       top: 2px;
       left: 3px;

       rotate: -5deg;
       transition: all .4s ease-out;
      
     };

     @media screen and (min-width: 1200px) {
     
     };
}
     
.basketIcon.nonEmpty {
   fill: #949494;
   opacity: .8;
}


.basketBadge {
   position: absolute;
   left: -1px;
   top: 1px;
   display: flex;
   justify-content: end;
   align-items: center;
   height: 50px;
   width: 50px;
   background-image: url('images/checkDelivery.svg');
   background-size: contain;
   color: #f3000000;
   z-index: 2;

   @media screen and (min-width: 767.9px) {
      height: 55px;
      width: 55px;
      /* font-size: 25px; */
      left: -5px;
      top: -4px;
      rotate: -5deg;
      };

      @media screen and (min-width: 1200px) {
     
      };
}
