.fadeOut {
  opacity: 0; 
}

/* basket */
.basket {
  position: relative;
  min-height: 85vh;
   
  @media screen and (min-width: 767.9px) {
      
  };
  @media screen and (min-width: 1200px) {
    
  };
 }



/* discriptionBox */


/* basketTitle */
.basketTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95px;
  font-size: 15px;
  margin: 0;
  color: transparent;

  @media screen and (min-width: 767.9px) {
    
  };
  @media screen and (min-width: 1200px) {
    
  };
  
}

/* menuCardImage */
.menuCardImage {
  @media screen and (max-width: 767.9px) {
     display: none;
    };
  }



/* toMenuButton */
.toMenuButton {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 21px;
  right: 20px; 
  height: 60px;
  width: 60px;
  border-radius: 13px;
  overflow: hidden;
  border: none;
  box-shadow:  4px 4px 15px 2px #00000078;
  transition: all .4s ease-out;
  z-index: 9;
  background-color: #262626;
  background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
   
   @media screen and (min-width: 767.9px) {
      width: 150px;
      flex-direction: row-reverse;
      right: 40px; 
     
   };

      @media screen and (min-width: 1200px) {
         
         right: 80px; 
        
      };
}

.toMenuButton:hover .toMenuDescription {
 
  color: #dddddd;
      transition: all .4s ease-out;
 }

 .toMenuButton:hover {
  scale: 1.03;
  transition: all .4s ease-out;
  box-shadow:  4px 4px 15px 2px #0000002d;
}


/* toMenuLink */
.toMenuLink {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  height: 100%;
  width: 100%;
  box-shadow:
   inset #000000b0 -2px -2px 9px,
   inset #7d7d7d93 2px 2px 9px,
   0px 0px 12px 1px rgba(0, 0, 0, 0.577); 
   
     @media screen and (min-width: 767.9px) {
        flex-direction: row;
        justify-content: end;
     };

        @media screen and (min-width: 1200px) {
     
        };
 }


/* toMenuIcon */
.toMenuIcon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateY(180deg);
  /* opacity: .6; */
  height: 41px;
  width: 41px;
  top: 9px;
  right: 9px;
   

   @media screen and (min-width: 767.9px) {
      margin-bottom: 0px;
      height: 55px;
      width: 55px;
      top: 5px;
      left: -3px;

      rotate: -5deg;
      transition: all .4s ease-out;
     
    };

    /* @media screen and (min-width: 1200px) {
      right: -7px;
      rotate: -5deg;
    }; */
}


/* toMenuDescription */
.toMenuDescription {
  margin: 0;
 
  transition: all .4s ease-out;
  color: #949494;
  /* color: #949494; */
  font-weight: 900;
  margin-bottom: 6px;
  display: none;

   @media screen and (min-width: 767.9px) {
     display: flex;
    margin-bottom: 0px;
    margin-right: 13px;
    font-size: 16px;
    text-shadow: 1px 1px 1px black;
 };

    @media screen and (min-width: 1200px) {
     
    };
}


/* basketContainer */
.basketContainer {
  padding: 0 5px;

 @media screen and (min-width: 767.9px) {
   padding: 0 150px;
 };
 
 @media screen and (min-width: 1200px) {
   padding: 0 300px;
 };
}




/* emptyOrderBasketBox */
.emptyOrderBasketBox {
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 13px;
  /* overflow: hidden; */
  /* box-shadow:  4px 4px 15px 2px #00000078; */
  /* margin: 0 20px; */
}



/* orderDone */



/* orderDoneMessage */
.orderDoneMessage,
.basketEmptyMessage {
   display: flex;
   align-items: center;
   /* background-color: #fff; */
   
 
 @media screen and (min-width: 767.9px) {
  
 };
 @media screen and (min-width: 1200px) {
 
 };
}

.orderDoneMessage h2,
.basketEmptyMessage h2 {
  /* background-color: red; */
 display: flex;
 width: 100%;
 justify-content: center;
 text-align: center;
 font-size: 15px;
      line-height: 45px;
      color: #949494;
      text-shadow: 1px 1px 1px rgb(0, 0, 0);
      font-weight: 900;
      /* letter-spacing: 0.1px; */

 @media screen and (min-width: 767.9px) {
  font-size: 15px;
  /* line-height: 65px; */
 };
 @media screen and (min-width: 1200px) {
  font-size: 17px;
  /* line-height: 75px; */
 };
}


/* basketBox */



/* emptyOrderBasketBox */



/* basketEmpty */



/* basketEmptyMessage */



/* basketListBox */
.basketListBox {
position: relative;
}


/* basketList */
.basketList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 15px;
  border: none;
  padding: 15px 5px 10px;
  margin-bottom: 50px;
  border-radius: 15px;
  box-shadow:
  inset rgba(42, 41, 41, 0.471) -5px -5px 9px,
  inset #303030 5px 5px 9px,
  rgba(0, 0, 0, 0.382) 3px 3px 8px 3px;
  background: rgba(0, 0, 0, 0.285);

  z-index: 9;
     
  @media screen and (min-width: 767.9px) {
     padding: 15px 15px 15px;
  };

 @media screen and (min-width: 1200px) {
  padding: 20px 20px 20px;
 };
}



/* basketTitled */
.basketTitled {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #949494;
  text-shadow: 1px 1px 1px rgb(0, 0, 0);
  font-size: 13px;
  margin: 0;
  padding: 20px 0 10px;

@media screen and (min-width: 767.9px) {
  font-size: 15px;
  margin-bottom: 10px;
};
@media screen and (min-width: 1200px) {
  font-size: 17px;
  margin-bottom: 20px;
};
}


/* basketListItem */
.basketListItem {
  position: relative;
   display: flex; 
   align-items: center;
   justify-content: space-between;
   height: 60px;
   /* margin-bottom: 17px; */
   transition: all .4s ease-out; 
   border-radius: 15px;
   background-color: #262626;
   background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
   /* box-shadow:
      inset #000000b0 -2px -2px 9px,
      inset #7d7d7d93 2px 2px 9px,
      0px 0px 12px 1px rgba(0, 0, 0, 0.577);  */
   overflow: hidden;

   /* @media screen and (min-width: 768px) {
      height: 65px;
      margin-bottom: 13px;
   };

   @media screen and (min-width: 1200px) {
      height: 70px;
      margin-bottom: 16px;
   }; */
}

.basketListItem:hover {
  transition: all .4s ease-out;
   scale: 1.02;
}



/* basketListItemInfo */
.basketListItemInfo {
  display: flex;
  flex-direction: column;
  height: 60px;
  width: 100%;
 
 }
 



.basketListItemCategory {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 55px;
  padding-bottom: 5px;
  /* background-color: rgba(0, 64, 100, 0.825); */
}



/* basketListItemCategoryDescription */
/* .basketListItemCategoryDescription {
 

   font-family: PoiretFont;

   display: flex;
   align-items: center;
   padding-left: 15px;
   font-size: 12px;
   color: #e9e9e9;
   color: #000000;
   font-weight: 900;
   text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.559);

   @media screen and (max-width: 374.9px) {
      font-size: 9px;


   };

   @media screen and (min-width: 767.9px) {
      font-size: 12px;
 
   };
   @media screen and (min-width: 1200px) {
      font-size: 13px;
   
   };
} */


.basketListItemCategoryDescription {
  font-family: MainFonts;
  display: inline;
  font-size: 13px;
  padding-left: 10px;
  font-style: italic;
  color: #a5a5a5;
  letter-spacing: .8px;
  /* font-weight: 900; */
  text-shadow: 1px 1px 1px rgb(0, 0, 0);

  @media screen and (max-width: 374.9px) {
     font-size: 10px;
  };

  @media screen and (min-width: 767.9px) {
     font-size: 13px;
     padding-left: 15px;
  };

  @media screen and (min-width: 1200px) {
     font-size: 16px;
     padding-left: 20px;
  };

}

.basketListItemName {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 55px;


  @media screen and (min-width: 767.9px) {
     
  };
  @media screen and (min-width: 1200px) {
     
  };
}



.basketListItemNameDescription  {
  /* display: flex;
  padding-left: 5px;
  margin: 0;
  color: #949494;
  text-shadow: 1px 1px 1px black;
  font-size: 10px; */
  display: flex;
   align-items: center;
   padding-left: 15px;
   font-size: 12px;
  
   color: #dddddd;
   font-weight: 900;
   text-shadow: 1px 1px 1px black;

   @media screen and (max-width: 374.9px) {
      font-size: 9px;
      /* padding-left: 1px; */

   };

   @media screen and (min-width: 767.9px) {
      font-size: 17px;
      /* padding: 35px 0 0 20px; */
      /* padding-left: 10px; */
   };
   @media screen and (min-width: 1200px) {
      font-size: 19px;
      /* padding: 35px 0 0 20px; */
      padding-left: 25px;
   };
}



.basketListItemIngredients {
  display: flex;
  justify-content: start;
  align-items: center;
  /* padding-left: 5px; */
  width: 100%;
  height: 55px;
  /* background-color: rgba(83, 0, 100, 0.825); */

  @media screen and (min-width: 767.9px) {
     
  };
  @media screen and (min-width: 1200px) {
     
  };
}



.basketListItemIngredientsDescription {
  display: flex;
  padding-left: 5px;
  margin: 0;
  color: #949494;
  font-size: 10px;
}



.basketListItemManagement {
  display: flex;

}

.basketListItemCounterInfo {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 80px;
 display: flex;
 flex-direction: column;
 /* background-color: #fff; */
}



.basketListItemQuantity {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  height: 50%;
 border-bottom: 1.5px solid #000;
 font-size: 15px;
 text-shadow: 1px 1px 1px black;
 color: #dddddd;
}

.basketListItemPrice {
  display: flex;
border-top: 1.5px solid #000;
justify-content: center;
  align-items: center;
  font-size: 15px;
  text-shadow: 1px 1px 1px black;
height: 50%;
color: #dddddd;

}

/* basketListItemPrice */



/* basketListItemCounter */
.basketListItemCounter {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  height: 60px;
  /* width: 150px; */
  margin: 0;
  padding: 0;
  /* padding: 0 7px; */
  font-size: 19px;
  /* background: #1c1c1cc5; */
  /* background: #0000006d; */
  /* background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png"); */
  border-radius: 0 13px 13px 0;
  border-radius: 13px;
  /* border-left: 2px solid #262626; */
  margin-left: 2px;

 @media screen and (min-width: 767.9px) {
     
 };

 @media screen and (min-width: 1200px) {
   
 };
}

.basketListItemDecreaseBox {
 display: flex;
 align-items: center;
 justify-content: end;
 height: 100%;
 width: 55px;
 /* background-color: #fff; */
}

.basketListItemIncreaseBox {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 100%;
  /* width: 100%; */
 width: 55px;

  /* background-color: #fff; */
 }
/* basketListItemIncrease */
/* basketListItemDecrease */
.basketListItemIncrease,
.basketListItemDecrease {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 40px;
  height: 40px;
  border: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  border-radius: 9px;
  transition: all 0.4s ease-out;
  background: transparent;
  
  @media screen and (min-width: 767.9px) {
      
  };

  @media screen and (min-width: 1200px) {
    
  };
}

.basketListItemIncrease:hover,
.basketListItemDecrease:hover {
 cursor: pointer;
 background-color: rgba(255, 255, 255, 0.36);
}



/* basketListItemQuantity */







/* basketOrderInfo */
.basketOrderInfo {
  display: grid;
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
  padding-top: 30px;
  border-top: 1px solid transparent;
  border-image: linear-gradient(0.25turn, #101010, #2a2a2a, #101010);
  border-image-slice: 1;
  /* background-color: #fff; */
 
  
  @media screen and (min-width: 767.9px) {
    /* padding: 0 70px; */
  };
  @media screen and (min-width: 1200px) {
    padding: 30px 60px 0;
  };

}



/* basketQuantityBox */
.basketQuantityBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  /* padding: 0 20px; */
 border-radius: 13px;
 background-color: #262626;
 background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
 /* box-shadow:
 inset #000000b0 -2px -2px 9px,
 inset #7d7d7d93 2px 2px 9px, 
 0px 0px 12px 1px rgba(0, 0, 0, 0.577); */
  @media screen and (min-width: 768px) {
    
  };
  @media screen and (min-width: 1200px) {
  
   
  };
 }
 



/* basketQuantityTitle */
/* basketPriceTitle */
.basketQuantityTitle,
.basketPriceTitle {
  display: grid;
  padding-left: 25px;
  font-size: 10px;
  color: #dddddd;
  text-shadow: 1px 1px 1px black;

  
  @media screen and (min-width: 767.9px) {
    padding-left: 35px;
    font-size: 12px;
  };
  
  @media screen and (min-width: 1200px) {
    padding-left: 35px;
    font-size: 13px;
  };
  }

.basketQuantityTitle h3,
.basketPriceTitle h3 {
margin: 0;
}



/* basketTotalQuantity */
/* basketTotalPrice */
.basketTotalQuantity,
.basketTotalPrice {
   color: #dddddd;
   padding-right: 25px;
   font-size: 22px;
   text-shadow: 1px 1px 1px black;

 @media screen and (min-width: 767.9px) {
  font-size: 22px;
  padding-right: 35px;
 };
 @media screen and (min-width: 1200px) {
  font-size: 22px;
  padding-right: 45px;
 };
}

/* basketQuantityBox */











/* basketMakeOrder */
.basketMakeOrder {
  display: flex;
  justify-content: center; 
  

   @media screen and (min-width: 767.9px) {
   };
 
   @media screen and (min-width: 1200px) {
   };
 }


 
 .buttonMakeOrderDescription {
  color: #c81b1b;
  display: flex;
  padding: 0;
  margin: 0;
  font-family: PrimaryFont;
  justify-content: center;
  align-items: center;
  border-radius: 17px;
  width: 100%;
  height: 100%;
  box-shadow:  4px 4px 15px 4px #0000008b;
  
  transition: all .4s ease-out;
}

.buttonMakeOrderDescription:hover {
  box-shadow:  2px 2px 15px 2px #0000005d;
  transition: all .4s ease-out;
  scale: 1.03;
 }

 .buttonMakeOrder.disabled {
  /* color: red; */
  cursor: not-allowed;
  position: relative;
  transition: background-color 0.5s; 
  text-shadow: 1px 1px 1px black;
  background-color: #262626;
  background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
  box-shadow:
  inset #000000b0 -2px -2px 9px,
  inset #7d7d7d93 2px 2px 9px, 
  0px 0px 12px 1px rgba(0, 0, 0, 0.577);
  
@media screen and (min-width: 767.9px) {
    
};
@media screen and (min-width: 1200px) {
  
};
}

.buttonMakeOrder.disabled:hover::after {
  content: "Заповніть всі поля";
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-50%);
  background-color: rgb(0, 0, 0);


  /* ============================================== */
  font-family: PrimaryFont;

  color: rgb(177, 177, 177);
  /* padding: 6px 0px; */
  border-radius: 16px;
  font-size: 13px;
  white-space: nowrap;
  transition: opacity .4s ease-out; 
  opacity: 0; 
  text-shadow: 1px 1px 1px black;
  background-color: #262626;
  background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
  box-shadow:
     inset #000000b0 -2px -2px 9px,
     inset #7d7d7d93 2px 2px 9px, 
     0px 0px 12px 1px rgba(0, 0, 0, 0.577);
@media screen and (min-width: 767.9px) {
    
};
@media screen and (min-width: 1200px) {
  
};
}

.buttonMakeOrder.disabled:hover::after {
  opacity: 1; 
  transition: opacity .4s ease-out; 

  
@media screen and (min-width: 767.9px) {
    
};
@media screen and (min-width: 1200px) {
  
};
}

.buttonMakeOrder.active .buttonMakeOrderDescription{
     color: white;
     text-shadow: 1px 1px 1px black;
     text-shadow: 1px 1px 1px black;
     background-color: #262626;
     background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
     box-shadow:
     inset #000000b0 -2px -2px 9px,
     inset #7d7d7d93 2px 2px 9px, 
     0px 0px 12px 1px rgba(0, 0, 0, 0.577);
     
  @media screen and (min-width: 767.9px) {
    
  };
  @media screen and (min-width: 1200px) {
    
  };
}

.buttonMakeOrder.active:hover {
cursor: pointer;
   
@media screen and (min-width: 767.9px) {
    
};
@media screen and (min-width: 1200px) {
  
};
}


/* basketInputBox */
.basketInputBox {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 15px;
  padding: 20px;
  border-radius: 13px;
  margin-top: 30px;
  box-shadow: 7px 7px 15px 2px #00000047;
  border-radius: 13px;
  box-shadow:
  inset rgba(42, 41, 41, 0.471) -5px -5px 9px,
  inset #303030 5px 5px 9px,
  rgb(0, 0, 0) 3px 3px 8px -3px;

   
 @media screen and (min-width: 767.9px) {
  width: 65%;
 };
 @media screen and (min-width: 1200px) {
  width: 55%;
 };
}


/* basketInput */
.basketInput {
  position: relative;
   /* background-color: rgb(0, 0, 0); */
   /* color: #000000; */
   font-family: PrimaryFont;
   padding-left: 16px;
   height: 50px;
   border: none;
   border-radius: 11px;
   border: 1px solid rgb(0, 0, 0);
   color: rgb(222, 222, 222);
   font-size: 17px;
   background-color: #262626;
   background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
   /* box-shadow:
   inset #000000a4 3px 3px 3px,
   inset #00000018 3px 3px 7px;  */
   transition: all .4s linear;

   

    
 @media screen and (min-width: 767.9px) {
     
 };
 @media screen and (min-width: 1200px) {
   
 };
}

.basketInput:hover,
.basketInput:focus,
.basketInput:active {
  border: 1px solid #4c0000;
}




/* basketInputAddressBox */
.basketInputAddressBox {
  display: flex;
  justify-content: space-between;

  
@media screen and (min-width: 767.9px) {
    
};
@media screen and (min-width: 1200px) {
  
};
}




/* basketInputStreet */
.basketInputStreet {
  width: 230px;
  width: 100%;

   
@media screen and (min-width: 767.9px) {
    
};
@media screen and (min-width: 1200px) {
  
};
}


/* basketInputStreetNumber */
.basketInputStreetNumber {
  width: 40px;
margin-left: 10px;
  
@media screen and (min-width: 767.9px) {
    
};
@media screen and (min-width: 1200px) {
  
};
}


/* buttonMakeOrderBox */
.buttonMakeOrderBox {
  display: flex;
  justify-content: center;
  padding: 40px 0;
  width: 100%;
  }


/* buttonMakeOrder */
/* isOrderButtonActive */
.buttonMakeOrder,
.buttonMakeOrder.active {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 60px;
  width: 150px;
  border: none;
  border-radius: 17px;
  background-color: #010101;
  transition: all .4s ease-out;
  box-shadow:
  inset rgba(42, 41, 41, 0.471) -4px -4px 9px,
  inset #303030 4px 4px 9px,
  rgb(183, 8, 8) 0px 0px 8px 3px;
  transition: all .4s ease-out;

  color: rgb(222, 222, 222);
  font-size: 17px;
  background-color: #262626;
  background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
      
  @media screen and (min-width: 767.9px) {

  };

  @media screen and (min-width: 1200px) {
   
  };
    
}






/* buttonMakeOrderDescription */
.buttonMakeOrderDescription {
  color: #999;
 display: flex;
 padding: 0;
 margin: 0;
 font-family: PrimaryFont;
 justify-content: center;
 align-items: center;
 border-radius: 17px;
 width: 100%;
 height: 100%;
 box-shadow:
     inset #000000b0 -2px -2px 9px,
     inset #7d7d7d93 2px 2px 9px, 
     0px 0px 12px 1px rgba(0, 0, 0, 0.577);
 background-color: #262626;
 background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
 transition: all .4s ease-out;
}

