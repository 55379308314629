.fadeOut {
   opacity: 0; 
   /* transition: opacity 1s ease-out; */
 }

.contactsPageContainer {
width: 100%;
}

.contactsPage {
   width: 100%;
   background-repeat: repeat;
   background-position: top;
   background-color: #000000;
   background-image: url("https://www.transparenttextures.com/patterns/white-wall-3-2.png");
   background-attachment: none;

      @media screen and (min-width: 768px) {
         
      };

      @media screen and (min-width: 1200px) {
    /* background-color: #020202; */

         background-attachment: fixed;
      };
}

 .mapBox {
   position: relative;
   display: flex;
   margin-left: auto;
   margin-right: auto;
   background:url("images/inMap.png");
   background-size: 250%;
   background-position: center;
   height: 450px;
   z-index: 0;
   box-shadow: 0 0 20px 22px #00000064;
   margin-bottom: 30px;

   @media screen and (min-width: 768px) {
      height: 500px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
   };
   @media screen and (min-width: 1200px) {
      height: 700px;
   };
}

.mapBox::after {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: #02020207;
   z-index: -1;
   background-image: url("https://www.transparenttextures.com/patterns/white-wall-3-2.png");
}

.contactsListBox {
   display: flex;
   padding:  0 20px  20px ;
   flex-direction: column;

   @media screen and (min-width: 767.9px) {
      display: grid;
      /* width: 300px; */
      padding:  0 20px 20px ;
      height: 450px;
      justify-content: center;
      align-items: center;
      /* background-color: #fff; */
   };
   @media screen and (min-width: 1200px) {
     
      height: 550px;
      /* width: 100%; */
   };
  }

 .contactsList {
   display: grid;
   /* grid-template-columns: repeat(1, 1fr); */
   grid-row-gap: 10px;
   width: 100%;
   padding-bottom: 10px;
   /* background-color: #fff; */
   

   @media screen and (min-width: 767.9px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 30px;
      
      /* padding: 0 30px; */
   };

   @media screen and (min-width: 1200px) {
      /* grid-template-columns: repeat(3, 1fr); */
      grid-column-gap: 50px;
     
   };
  }

 .contactsListItem {
   position: relative;
   display: flex;
   overflow: hidden;
   justify-content: flex-end;
   align-items: center;
   height: 70px;
   width: 100%;
   font-size: 13px;
   border-radius: 13px;
   margin:  3px 0;
   border-radius: 13px;
   box-shadow: rgba(0, 0, 0, 0.608) 3px 3px 10px 4px;
  
   transition: all .4s ease-out;
   @media screen and (min-width: 767.9px) {
      font-size: 13px;
      width: 350px;
   };

   @media screen and (min-width: 1200px) {
      font-size: 15px; 
      width: 500px;
     
   };
  }



.contactsListItem img {
   position: absolute;
   rotate: -5deg;
   /* opacity: .6; */
   display: block;
   height: 55px;
   transition: all .4s ease-out;
   left: 10px;
   scale: .9;

   @media screen and (min-width: 767.9px) {
      padding-left: 10px;

   };

   @media screen and (min-width: 1200px) {
   padding-left: 20px;

   };
}

.contactsListItemActive:hover,
.contactsListItemActive:active {
 
   box-shadow: rgba(0, 0, 0, 0.273) 3px 3px 10px 6px;
   transition: all .4s ease-out;
   scale: 1.02;
}

.contactsListItemActive:hover img {
   /* rotate: 0deg; */
   /* opacity: 0.5; */
   display: block;
   transition: all .4s ease-out;
   left: 10px;
   
   @media screen and (min-width: 767.9px) {

   };

   @media screen and (min-width: 1200px) {

   };
}

.contactsListItemDiscriptions {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   color: #060606;
   color: #949494;
   transition: all .4s ease-out;
    width: 100%;
    height: 100%;
    text-shadow: 1px 1px 1px rgb(0, 0, 0);
    /* transition: all .4s ease-out; */
    border-radius: 13px;
    font-size: 14px;
    font-weight: 900;
    padding-right: 20px;
/* background-color: #111111; */
/* background-image: url("https://www.transparenttextures.com/patterns/solid.png"); */
background-color: #262626;
/* background-color: #585858; */
background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */

box-shadow:
inset #000000b0 -3px -3px 9px,
inset #7d7d7d93 3px 3px 9px ; 

   @media screen and (min-width: 767.9px) {
      padding-right: 30px;
      font-size: 15px;
     
   };
   @media screen and (min-width: 1200px) {
      padding-right: 50px;
      font-size: 16px;
   letter-spacing: .5px;
   };
  
}

.contactsListItemActive:hover .contactsListItemDiscriptions,
.contactsListItem:active .contactsListItemDiscriptions {
   color: #dbdbdb;
   transition: all .4s ease-out;
}

/* .contactsListItemActive:hover ,
.contactsListItem:active  {
   scale: 1.01;
   transition: all .4s ease-out;
} */
