/* index.css */
@import-normalize; /* bring in normalize.css styles */

* {
  list-style-type: none;
  text-decoration: none;
  text-align: justify;
}

ul {
padding: 0;
margin: 0;
}

body, html {
  height: 100%;
}

/* a {
color: #9f9f9f;


height: 100%;
font-weight: 900;
display: flex;
    align-items: center;
} */

body {
  margin: 0;
  padding: 0;
  font-family: 'PrimaryFont';
  /* font-family: 'AmagroFont'; */
  font-weight: 100;
  color: #9f9f9f;
  background: #000000;
  font-size: 12px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@font-face {
  font-family: PrimaryFont;
  src: url(./data/fonts/Constantine.ttf) format("truetype");
}

@font-face {
  font-family: PoiretFont;
  src: url(./data/fonts/Cardo.ttf) format("truetype");
}

@font-face {
  font-family: AnticvaFont;
  src: url(./data/fonts/Anticva.otf) format("truetype");
}

@font-face {
  font-family: AmagroFont;
  src: url(./data/fonts/Amagro.ttf) format("truetype");
}



