/* BurgerMenu.module.css */

.burgerMenu {
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  top: 70px;
  right: 0;
  z-index: 100;
font-size: 14px;
  width: 100%;
  height: 70px;

  background-color: #000000;

  background-image: url("https://www.transparenttextures.com/patterns/egg-shell.png");
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(0.25turn, #101010, #2a2a2a, #101010);
  border-image-slice: 1;
  opacity: 0;
  transition: transform .4s ease-out, opacity .6s ;
  transform: translateX(-100%);
}



.isOpen {
  transform: translateX(0%);
  opacity: 1;
  transition: transform .4s ease-out, opacity .2s ;

}

.burgerMenu ul {
  width: 100%;
  display: flex;
  align-items: center;
  opacity: 1;
  justify-content: space-around;
}

.headerLinkText {
  color: #949494;
  text-shadow: 1px 1px 1px #2c2c2c;
  transition: all .4s ease-out;
}

 .activeLink h4 {
 color: #400000;
 text-shadow: 1px 1px 1px #270000;
 transition: all .4s ease-out;
}


.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000a2;
  z-index: 99;
  opacity: 1; 
}
