.dishPage {
   position: relative;
   min-height: 85vh;
   background-repeat: repeat;
   background-color: #000000;
   background-position: top;
   background-image: url("https://www.transparenttextures.com/patterns/white-wall-3-2.png");
   background-attachment: none;

     @media screen and (min-width: 1200px) {
      background-attachment: fixed;
     };

}

.dishPageContainer {
   padding: 10px 5px;
  
   @media screen and (min-width: 767.9px) {
    padding: 20px 120px;
   };
   @media screen and (min-width: 1200px) {
    padding: 30px 250px;
   };
  }


.discriptionBox {
   display: flex;
   position: relative;
   justify-content: center;
   align-items: center;
  }

  .menuCardImage {
   @media screen and (max-width: 767.9px) {
      display: none;
     };
}

.dishTitle {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 95px;
   font-size: 12px;
   margin: 0;
   color: #999999;
   text-shadow: 1px 1px 1px #444444bc;

   @media screen and (min-width: 768px) {
     
   };
   @media screen and (min-width: 1200px) {
    
   };
}

.dishPageBox {
   display: grid;
   width: auto;
   height: auto;
   
  @media screen and (min-width: 767.9px) {
       
  };
  @media screen and (min-width: 1200px) {
    
  };
  }

.dishList {
   display: grid;
   grid-template-columns: repeat(1, 1fr);
   grid-row-gap: 40px;
   border: none;
   padding: 25px 5px 0px;
   margin-bottom: 50px;
   border-radius: 15px;
   box-shadow:
   inset rgba(42, 41, 41, 0.471) -5px -5px 9px,
   inset #303030 5px 5px 9px,
   rgba(0, 0, 0, 0.853) 3px 3px 15px 3px;
   z-index: 9;
   background: rgba(0, 0, 0, 0.5);

    /* background-image: url("images/hotel.png"); */
/* background-attachment: fixed; */
   @media screen and (min-width: 767.9px) {
      padding: 15px 15px 5px;
   };

  @media screen and (min-width: 1200px) {
   padding: 20px 20px 5px;
  };
}


.dishListTitle {
   display: flex;
   justify-content: center;
   width: 100%;
   margin: 0 0 10px 0;
   color: #000000;
   color: #999999;
   font-size: 13px;

   @media screen and (min-width: 767.9px) {
      font-size: 15px;
     };
     @media screen and (min-width: 1200px) {
      font-size: 17px;
     };
}

.dishListItem {
   position: relative;
   display: flex; 
   align-items: center;
   justify-content: space-between;
   margin-bottom: 15px;
   transition: all .4s ease-out; 
   border-radius: 11px;
   background-color: #313131;
   background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");

   /* box-shadow:
      inset #000000b0 -3px -3px 9px,
      inset #7d7d7d93 3px 3px 9px;  */
   overflow: hidden;

   @media screen and (min-width: 768px) {
      margin-bottom: 20px;
   };

   @media screen and (min-width: 1200px) {
      margin-bottom: 25px;
   };
 }


 .dishListItemInfo {
   display: flex;
   flex-direction: column;
   height: 60px;
   width: 100%;
   padding-right: 5px;
}

 .dishListItemCategory {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 55px;
}

.dishListItemCategoryDescription {
   font-family: PoiretFont;

   display: flex;
   align-items: center;
   padding-left: 15px;
   font-size: 12px;
   color: #e9e9e9;
   font-weight: 900;
   text-shadow: 1px 1px 2px black;


   @media screen and (max-width: 374.9px) {
      font-size: 9px;
   };

   @media screen and (min-width: 767.9px) {
      font-size: 17px;
   };

   @media screen and (min-width: 1200px) {
      font-size: 19px;
      padding-left: 25px;
   };

}

.dishListItemName {
   display: flex;
   display: block;
   align-items: center;
   word-break: break-word;
   text-align: left;
   width: 95%;
   height: 55px;
   padding-left: 10px;

   @media screen and (min-width: 767.9px) {
      padding-left: 15px;
   };
   @media screen and (min-width: 1200px) {
      padding-left: 20px;
   };
}

/* .dishListItemLunchPositionName {
 padding-left: 10px;

 @media screen and (min-width: 767.9px) {
   padding-left: 15px;
};
@media screen and (min-width: 1200px) {
   padding-left: 20px;
};
} */

.dishListItemNameDescription {
   display: flex;
   display: inline;
   align-items: center;
   font-size: 14px;
   color: #b0b0b0;
   /* color: #0000009f; */

   /* font-family: IndicoFont; */
   /* color: #cbcbcb; */
   font-weight: 900;
   text-shadow: 1px 1px 1px black;
   transition: all .4s ease-out;

   @media screen and (max-width: 374.9px) {
      font-size: 9px;
   };

   @media screen and (min-width: 767.9px) {
      font-size: 17px;
   };
   @media screen and (min-width: 1200px) {
      font-size: 19px;
      
   };

}


.dishListRazdelitel {
display: flex;
justify-content: center;

width: 100%;
height: 5px;
background-color: #000000;
border-radius: 2px;

}

.dishListLunchDelimiterBox {
   display: flex;
   justify-content: center;
   width: 100%;
   height: 2px;
  
}

.dishListLunchDelimiter {
 width: 97%;
 background-color: #0000009b;
 border-radius: 2px;
}



.dishLunchList {
   display: grid;
   grid-template-columns: repeat(1, 1fr);
   grid-row-gap: 0px;
   border: none;
   padding: 30px 5px 0px;
   margin-bottom: 50px;
   border-radius: 15px;
   box-shadow:
   inset rgba(42, 41, 41, 0.471) -5px -5px 9px,
   inset #303030 5px 5px 9px,
   rgb(0, 0, 0) 3px 3px 8px -3px;
   background: rgba(0, 0, 0, 0.5);
   z-index: 9;
   overflow: hidden;
      
   @media screen and (min-width: 767.9px) {
      padding: 30px 15px 0;
   };

  @media screen and (min-width: 1200px) {
   padding: 30px 20px 0;
  };
}


 .dishListItemLunch {
   position: relative;
   width: 100%;
   display: flex; 
   flex-direction: column;
   margin-bottom: 15px;
   transition: all .4s ease-out; 
   overflow: hidden;
   border-radius: 13px;
   margin-bottom: 30px;
  
   /* background-color: rgba(255, 255, 255, 0.186); */
   background-color: #313131;
   background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");

   box-shadow:
   inset #000000b0 -3px -3px 9px,
   inset #7d7d7d93 3px 3px 9px; 

   @media screen and (min-width: 768px) {
   
   };

   @media screen and (min-width: 1200px) {
  
   };
}

.dishListItemLunch:hover {
  scale: 1.01;
}

.dishListItemLunch:hover .dishListItemNameDescription {
   color: white;
 }

.dishListItemLunch .dishListItemLunchPosition:nth-child(n+2) {
 /* background-color: rgb(3, 22, 129); */
 color: red;
 height: 40px;
}

.dishListItemLunchManagement {
 display: flex;
 justify-content: space-between;
}


.dishListItemLunchPosition {
   display: flex;
   align-items: center;
   justify-content: space-between;
   height: 60px;
   padding: 0 10px;
   /* background-color: #fff; */
   @media screen and (min-width: 767.9px) {
      padding: 0 10px 0 20px;
   };

  @media screen and (min-width: 1200px) {
   padding: 0 10px 0 30px;
  };
}

.dishListItemLunchPositionAddBox {
   display: flex;
   justify-content: start;
align-items: center;
   /* background-color: blue; */
height: 70px;
width: 50px;
}
/* ul li:nth-child(n+2) */

.dishListItemLunchPositionInfo {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.dishListItemLunchPositionInfo td {
display: flex;
/* background-color: red; */
height: 50%;
width: 100%;
align-items: center;
justify-content: center;
}

.dishListItemLunchPositionManagement {
display: flex;
}
.dishListItemIngredients {
   display: flex;
   display: block;
   align-items: flex-start;
   word-break: break-word;
   text-align: left;
   padding-left: 15px;
   width: 95%;
   height: 55px;  
   padding-bottom: 5px;

   @media screen and (min-width: 767.9px) {
      
   };
   @media screen and (min-width: 1200px) {
      
   };
  
}


.dishListItemIngredientsDescription {
   font-family: MainFonts;
   display: inline;
   font-size: 13px;
   padding-left: 5px;
   font-style: italic;
   color: #a5a5a5;
   /* color: #000000a0; */

   letter-spacing: .8px;
   /* font-weight: 900; */
   text-shadow: 1px 1px 1px rgb(0, 0, 0);

   @media screen and (max-width: 374.9px) {
      font-size: 10px;
   };

   @media screen and (min-width: 767.9px) {
      font-size: 13px;
      padding-left: 7px;
   };

   @media screen and (min-width: 1200px) {
      font-size: 16px;
      padding-left: 10px;
   };
}

.dishListItemManagement,
.dishListItemManagementNotAdd {
   display: flex;
 }

.dishListWeightPriceBox {
 display: flex;
 flex-direction: column;
}




.dishListWeightPriceBoxNotAdd {
   display: flex;
   flex-direction: column;
   /* height: 70px; */

   @media screen and (min-width: 767.9px) {
   
   };

   @media screen and (min-width: 1200px) {
  
   }; 
}








.dishListItemWeigthNotAddHardRight {
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 0 0 0 5px;
   background: #00000087;
   height: 100%;
   border-bottom: 1px solid #000;  
   border-left: 1px solid #000;  
}



.dishListItemWeigth
 {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
   width: 80px;
   color: #999999;
   text-shadow: 1px 1px 1px black;

   @media screen and (min-width: 767.9px) {
      font-size: 15px;
      width: 90px;
   };

   @media screen and (min-width: 1200px) {
      font-size: 17px;
      width: 100px;
   }; 
  }

.dishListItemPrice {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
   /* border-top: 2px solid #141414; */
   color: #999999;
   text-shadow: 1px 1px 1px black;
  
   @media screen and (min-width: 767.9px) {
      font-size: 15px;
    
   };

   @media screen and (min-width: 1200px) {
      font-size: 17px;
     
   }; 
}

.dishListItemWeigthNotAdd {
   display: flex;
   align-items: center;
   justify-content: center;
   color: #999999;
   height: 100%;
   text-shadow: 1px 1px 1px #141414;
   /* border-bottom: 2px solid #000000;   */
   
   @media screen and (min-width: 767.9px) {
      font-size: 15px;
      width: 90px;
   };

   @media screen and (min-width: 1200px) {
      font-size: 17px;
      width: 100px;
   }; 

  }

.dishTablePriceNotAdd {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
   width: 70px;
   /* border-top: 2px solid #141414; */
    color: #999999;
    text-shadow: 1px 1px 1px #000;

   @media screen and (min-width: 767.9px) {
      font-size: 15px;
      width: 90px;
   };

   @media screen and (min-width: 1200px) {
      font-size: 17px;
      width: 100px;
   }; 
}



.dishListButtonAddBox {
   display: flex;
   align-items: center;
   justify-content: start;
   height: 60px;
   width: 50px;
   margin: 0;
   padding: 0;
   border-radius: 0 14px 14px 0;

/* 
   @media screen and (min-width: 767.9px) {
      height: 65px;
   
   };

   @media screen and (min-width: 1200px) {
      height: 70px;
    
   }; */
}

.dishListButtonAdd {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 40px;
   height: 40px;
   /* border: 1.5px solid rgba(134, 134, 134, 0.529); */
   padding: 0;
   margin: 0;
   overflow: hidden;
   border-radius: 10px;
   transition: all .4s ease-out;

}



.dishListItem:hover {
   transition: all .4s ease-out;
   scale: 1.02;
   /* background-color: rgba(0, 0, 0, 0.348); */

}

.dishListItem:hover .dishListItemNameDescription {
color: rgb(254, 254, 254);
transition: all .8s ease-out;

}








.toMenuButton {
   position: absolute;
   display: flex;
   flex-direction: column;
   top: 21px;
   right: 20px; 
   height: 60px;
   width: 60px;
   border-radius: 13px;
   overflow: hidden;
   border: none;
   box-shadow:  4px 4px 15px 2px #00000078;
   z-index: 9;
   transition: all .4s ease-out;

   background-color: #262626;
   background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
    
   @media screen and (min-width: 767.9px) {
       width: 150px;
       flex-direction: row-reverse;
       right: 40px; 
      
    };

       @media screen and (min-width: 1200px) {
          
          right: 80px; 
         
       };
}

.toMenuLink {
 position: relative;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: end;
 height: 100%;
 width: 100%;
 box-shadow:
  inset #000000b0 -5px -5px 9px,
  inset #7d7d7d93 5px 5px 9px; 
  
    @media screen and (min-width: 767.9px) {
       flex-direction: row;
       justify-content: end;
    };

       @media screen and (min-width: 1200px) {
    
       };
}

.toMenuDescription {
  margin: 0;
  transition: all .4s ease-out;
  color: #999999;
  font-weight: 900;
  margin-bottom: 6px;
  display: none;

   @media screen and (min-width: 767.9px) {
     display: flex;
    margin-bottom: 0px;
    margin-right: 13px;
    font-size: 16px;
    text-shadow: 1px 1px 1px rgb(0, 0, 0);
    
 };

    @media screen and (min-width: 1200px) {
     
    };
}

.toMenuButton:hover .toMenuDescription {

 color: #e9e9e9;
     transition: all .4s ease-out;
}

.toMenuButton:hover {
 scale: 1.03;
 transition: all .4s ease-out;
 box-shadow:  4px 4px 15px 2px #0000002d;
}

.toMenuIcon {
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
   transform: rotateY(180deg);
   /* opacity: .6; */
   height: 41px;
   width: 41px;
   top: 9px;
   right: 9px;
    

    @media screen and (min-width: 767.9px) {
       margin-bottom: 0px;
       height: 55px;
       width: 55px;
       top: 5px;
       left: -3px;
       rotate: -5deg;
       transition: all .4s ease-out;
      
     };

   
}




  
  
