.logoben {
   display: flex;
   
   height: 30px;
  

   @media screen and (min-width: 768px) {
    
      height: 35px;
   };

   @media screen and (min-width: 1200px) {
    
      height: 40px;
  
   };
}

