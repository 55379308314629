/*  MasterContainer.module.css  */

.masterContainer {
   display: flex;
   flex-direction: column;
   min-height: 100vh; 
   box-sizing: border-box;
   margin: 0 auto;
   width: 100%;
   background-color: black;
   
   @media screen and (min-width: 768px) {
    /* padding: 0 70px; */
    /* background-color: rgb(1, 15, 5); */
   };

  @media screen and (min-width: 1200px) {
    /* background-color: rgb(1, 15, 5); */
    /* padding: 0 70px; */
  }
 };