

/* ================================== */

.inMapButton {
   position: absolute;
   display: flex;
   flex-direction: column;
   top: 370px;
   right: 20px; 
   height: 60px;
   width: 60px;
   border-radius: 13px;
   overflow: hidden;
   border: none;
   box-shadow: rgba(0, 0, 0, 0.608) 3px 3px 10px 4px;

   transition: all .4s ease-out;
   background-color: #262626;
   background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
   z-index: 9;
    
    @media screen and (min-width: 767.9px) {
       width: 150px;
       flex-direction: row-reverse;
       /* right: 40px;  */
       top: 400px;
       right: 40px; 
      
    };

       @media screen and (min-width: 1200px) {
          
         top: 580px;
         right: 68px; 
         
       };
}

.inMapLink {
 position: relative;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: end;
 height: 100%;
 width: 100%;
 box-shadow:
inset #000000b0 -3px -3px 9px,
inset #7d7d7d93 3px 3px 9px ; 
  
    @media screen and (min-width: 767.9px) {
       flex-direction: row;
       justify-content: end;
    };

       @media screen and (min-width: 1200px) {
    
       };
}

.inMapDescription {
  margin: 0;
  font-size: 9px;
  transition: all .4s ease-out;
  color: #999999;
  /* color: #949494; */
  font-weight: 900;
  margin-bottom: 6px;
  display: none;
  text-shadow: 1px 1px 1px black;

   @media screen and (min-width: 767.9px) {
     display: flex;
    margin-bottom: 0px;
    margin-right: 12px;
    font-size: 15px;
    
 };

    @media screen and (min-width: 1200px) {
     
    };
}

.inMapButton:hover .inMapDescription {

 color: #eeeeee;
     transition: all .4s ease-out;
}

.inMapButton:hover {
 scale: 1.03;
 transition: all .4s ease-out;
 box-shadow:  4px 4px 15px 2px #0000002d;
 box-shadow: rgba(0, 0, 0, 0.273) 3px 3px 10px 6px;
}

.inMapIcon {
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
   transform: rotateY(180deg);
   /* margin-bottom: 3px; */
   /* opacity: .6; */
   /* rotate: -5deg; */
   height: 41px;
       width: 41px;
       top: 7px;
       right: 10px;
    

    @media screen and (min-width: 767.9px) {
     
     
       margin-bottom: 0px;
       height: 48px;
       width: 48px;
       top: 5px;
       left: 0px;

       rotate: -5deg;
       transition: all .4s ease-out;
      
     };

     /* @media screen and (min-width: 1200px) {
       right: -7px;
       rotate: -5deg;
     }; */
}