.logo {
   display: flex;

   height: 33px;
  
@media screen and (min-width: 767.9px) {
   height: 38px;
};

@media screen and (min-width: 1199.9px) {
   height: 43px;
}
 
}

