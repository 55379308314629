/* MainPage.module.css */

.fadeOut {
  opacity: 0; 
}

.mainPageContainer {
  width: 100%;
  background-position: left;
  background-color: #000000;
  background-image: url("https://www.transparenttextures.com/patterns/white-wall-3-2.png");

  @media screen and (min-width: 1200px) {
    background-attachment: fixed;
  }; 

}

 .hero {
  position: relative;
   display: flex;
   margin-left: auto;
   margin-right: auto;
   background:url("images/23232555.png");
   background-size: cover;
   background-position: center;
   overflow: hidden;
   height: 500px;
   box-shadow: 0 0 50px 22px #00000070;  
     

   @media screen and (min-width: 768px) {
      height: 600px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 30px;
   };
   @media screen and (min-width: 1200px) {
      height: 700px;
      margin-bottom: 50px;
      background-attachment: fixed;
   };
  }

    .topDescriptionsBox {
      display: flex;
      align-items: center;
      justify-content: center;
  
    @media screen and (min-width: 768px) {
      padding: 0 40px;
    };
    @media screen and (min-width: 1200px) {
      padding: 0 50px;
    };
    }
  
    .topDescriptions {
      display: flex;
      text-align: center;
      justify-content: center;
      padding: 0 10px;
      padding: 30px 0;
      width: 350px;
      font-size: 15px;
      line-height: 45px;
      color: #949494;
      color: #727272;

      text-shadow: 3px 3px 1px rgb(0, 0, 0);
      font-weight: 500;
      letter-spacing: 0.1px;
  
      @media screen and (min-width: 768px) {
        font-size: 19px;
        line-height: 55px;
        letter-spacing: 1.2px;
        font-weight: 700;
        height: 400px;
        width: 600px;
      };
      @media screen and (min-width: 1200px) {
        font-size: 22px;
        line-height: 65px;
        letter-spacing: 2.5px;
        font-weight: 900;
        height: 500px;
        width: 1100px;
      };
      }

  .featuresTopBox {
    display: flex;
    justify-content: center;
    height: 380px;
    justify-content: center;
    align-items: center; 
    box-shadow: 0 0 40px 20px #00000070;  
    background-color: #070707;
    background-image: url("https://www.transparenttextures.com/patterns/white-wall-3-2.png");
  
    @media screen and (min-width: 768px) {
      height: 700px;
      padding: 0 40px;
    };
    @media screen and (min-width: 1200px) {
      height: 600px;
    };
  }

 

.featuresTop  {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  margin: 0;
  padding: 0 20px;
 
  @media screen and (min-width: 768px) {
   
    grid-column-gap: 25px;
    grid-row-gap: 25px;
  };
  @media screen and (min-width: 1200px) {
     grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  };
 
}

  .featuresTop li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    border-radius: 14px;
    background-image: url('images/str2.png'); 
    background-color: #000000;
    background-size: 150%;
    background-position: top;
    background-repeat: repeat;
    overflow: hidden;
    /* box-shadow:
    inset #59595979 -5px -5px 9px,
    inset #565555b5 5px 5px 9px,
    rgba(0, 0, 0, 0.627) 3px 3px 10px 3px; */

    box-shadow:
    inset #89898978 -4px -4px 9px,
    inset #9a999978 4px 4px 9px,
    rgba(0, 0, 0, 0.608) 2px 2px 10px 6px;

    @media screen and (min-width: 768px) {
      /* background-size: 150%; */
      border-radius: 17px;
      height: 200px;
    };
    @media screen and (min-width: 1200px) {
      background-size: 45%;
      background-repeat: repeat;
      background-attachment: fixed;
      border-radius: 19px;
      height: 200px;
    };
  }

  .featuresTop li h1 {
   display: flex;
   align-items: center;
   height: 100%;
   width: 100%;
   justify-content: center;
   font-size: 15px;
    border-radius: 13px;
background: rgba(0, 0, 0, 0.474);

color: #787878;
color: #727272;

text-shadow: 1.5px 1.5px 1px #444444bc;
  @media screen and (min-width: 768px) {
    font-size: 17px;
  };
  @media screen and (min-width: 1200px) {
    font-size: 20px;
  };
  }

 
  .centerDescriptionsBox {
    display: flex;
    align-items: center;
    justify-content: center;

  @media screen and (min-width: 768px) {
    padding: 0 40px;
  };
  @media screen and (min-width: 1200px) {
    padding: 0 50px;
  };
  }

  .centerDescriptions {
     display: flex;
     text-align: center;
     justify-content: center;
     padding: 0 10px;
     padding: 30px 0;
     width: 370px;
     font-size: 15px;
     line-height: 45px;
     color: #949494;
 color: #727272;
     
     text-shadow: 3px 3px 1px rgb(0, 0, 0);
     font-weight: 500;
     letter-spacing: 0.4px;
 
     @media screen and (min-width: 768px) {
       font-size: 19px;
       line-height: 55px;
       letter-spacing: 1.2px;
       font-weight: 700;
       width: 600px;
       padding: 40px 0;
     };
     @media screen and (min-width: 1200px) {
       font-size: 22px;
       line-height: 65px;
       letter-spacing: 2.5px;
       font-weight: 900;
       width: 700px;
       padding: 50px 0;
     };
  }

  /*  */


  .featuresCenterBox {
    display: flex;
    height: 310px;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-shadow: 0 0 40px 20px #00000070; 
    background-color: #070707;
    background-image: url("https://www.transparenttextures.com/patterns/white-wall-3-2.png");

    @media screen and (min-width: 768px) {
      height: 400px;
    };
    @media screen and (min-width: 1200px) {
      height: 450px;
    };
  }

  .featuresCenter {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: 900px;  

    @media screen and (min-width: 768px) {
      grid-column-gap: 30px;
      grid-row-gap: 30px;
    };
    @media screen and (min-width: 1200px) {
      grid-column-gap: 40px;
      grid-row-gap: 40px;
    };

  }

  .featuresCenter li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px;
    width: 100%;
    padding: 30px auto;
    border-radius: 14px;
    background-color: #000000;
    background-image: url("images/hotel.png");
    background-size: 100%;
    background-repeat: repeat;
    border: none;
    box-shadow:
    inset #8b8b8b9c -5px -5px 9px,
    inset #aaaaaa9c 5px 5px 9px,
    rgba(0, 0, 0, 0.608) 2px 2px 10px 6px;

    @media screen and (min-width: 768px) {
      border-radius: 17px;
    };

    @media screen and (min-width: 1200px) {
      background-attachment: fixed;
      background-size: 20%;
    background-repeat: repeat;
    border-radius: 19px;
    };
   }

   .featuresCenter li h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 13px;
    font-size: 15px;
    color: #787878;
 color: #727272;

    text-shadow: 1.5px 1.5px 1px #444444bc;
    background: #0000009e;

    @media screen and (min-width: 768px) {
      font-size: 20px;
      border-radius: 17px;
    };
    @media screen and (min-width: 1200px) {
      font-size: 22px;
      border-radius: 19px;
    };
   }


   .bottomDescriptionsBox {
    display: flex;
    align-items: center;
    justify-content: center;

  @media screen and (min-width: 768px) {
    padding: 0 40px;
  };
  @media screen and (min-width: 1200px) {
    padding: 0 50px;
  };
  
  }
  
  .bottomDescriptions {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 0 10px;
    padding: 30px 0;
    width: 350px;
    font-size: 15px;
    line-height: 45px;
    color: #949494;
 color: #727272;

    text-shadow: 3px 3px 1px rgb(0, 0, 0);
    font-weight: 500;
    letter-spacing: 0.4px;

    @media screen and (min-width: 768px) {
      font-size: 19px;
      line-height: 55px;
      letter-spacing: 1.2px;
      font-weight: 700;
      width: 600px;
    };
    @media screen and (min-width: 1200px) {
      font-size: 22px;
      line-height: 65px;
      letter-spacing: 2.5px;
      font-weight: 900;
      width: 700px;
    };
    }


   .featuresBottomBox {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) {
    };
    @media screen and (min-width: 1200px) {
    };
   }

 
  .featuresBottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
   
    width: 100%;
    padding: 0 20px;

    @media screen and (min-width: 768px) {
      grid-column-gap: 15px;
      padding: 0 150px;
    };
    @media screen and (min-width: 1200px) {
      grid-column-gap: 20px;
      padding: 0 290px;
    };

    
   }
  
  .featuresBottom li {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 60px;
    border-radius: 13px;
    background-color: #262626;
    background-image: url("https://www.transparenttextures.com/patterns/soft-wallpaper.png");
    /* box-shadow:  rgba(0, 0, 0, 0.371) 2px 2px 10px 6px;; */
    box-shadow: rgba(0, 0, 0, 0.608) 0px 0px 11px 3px;

    

    @media screen and (min-width: 768px) {
    };

    @media screen and (min-width: 1200px) {
    };
   }

   .featuresBottom li h1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 12px;
    font-weight: 900;
   
 color: #999999;

    text-shadow: 1px 1px 1px #000000bc;
        box-shadow:
    inset #000000b0 -5px -5px 9px,
    inset #7d7d7d93 5px 5px 9px; 


    box-shadow:
inset #000000b0 -3px -3px 9px,
inset #7d7d7d93 3px 3px 9px ; 

    @media screen and (min-width: 768px) {
      font-size: 15px;
    };
    @media screen and (min-width: 1200px) {
      font-size: 19px;
    };
   }

.bottomDeliveryBox {
  display: flex;
  justify-content: center;
  align-items: center;
 height: 100px;
 width: 100%;

 @media screen and (min-width: 767.9px) {
  height: 150px;
};
@media screen and (min-width: 1200px) {
  height: 200px;
};

}
.bottomDeliveryDescriptions {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 0 10px;
  padding: 30px 0;
  width: 370px;
  font-size: 15px;
  line-height: 45px;
  color: #727272;
  text-shadow: 3px 3px 1px rgb(0, 0, 0);
  font-weight: 500;
 
     @media screen and (min-width: 768px) {
       font-size: 19px;
       line-height: 55px;
       letter-spacing: 1.2px;
       font-weight: 700;
       width: 600px;
       padding: 40px 0;
     };
     @media screen and (min-width: 1200px) {
       font-size: 22px;
       line-height: 65px;
       letter-spacing: 2.5px;
       font-weight: 900;
       width: 700px;
       padding: 50px 0;
     };
  };
